import 'unpoly/unpoly.min'
import Splide from '@splidejs/splide'
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.ch')
import hyperform from 'hyperform'
import Plyr from 'plyr'
import VenoBox from 'venobox'

up.compiler('body', () => {
    document.querySelector('html').classList.remove('no-js')
})

up.compiler('.js-navbar .dropdown > .nav-link', (element) => {
  let screenXL = window.matchMedia('(min-width: 1280px)')
  let screenTouch = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
  const navLinks = document.querySelectorAll('.js-navbar .dropdown > .nav-link')
  const backButton = element.parentElement.querySelector('.js-back')

  const showDropdownMenu = event => {
    if (!screenXL.matches && screenTouch) {
      event.preventDefault()
      navLinks.forEach((navLink) => {
        navLink.parentElement.querySelector('.dropdown-menu').classList.remove('show')
      })
      element.parentElement.querySelector('.dropdown-menu').classList.add('show')
    }
  }
  const closeDropdownMenu = () => backButton.parentElement.classList.remove('show')

  element.addEventListener('click', showDropdownMenu)
  backButton.addEventListener('click', closeDropdownMenu)

  return () => {
    element.removeEventListener('click', showDropdownMenu)
    backButton.removeEventListener('click', closeDropdownMenu)
  }
})

up.compiler('.js-nav-toggle', (navToggle) => {
  const offCanvas = document.querySelector('.off-canvas')

  const toggleOffCanvas = () => {
    offCanvas.classList.toggle('-translate-x-full')
  }

  navToggle.addEventListener('click', toggleOffCanvas)

  return () => navToggle.removeEventListener('click', toggleOffCanvas)
})

up.compiler('.js-slider', (element) => {
  new Splide(element).mount()
})

up.compiler('.js-text-block, .js-content-block', (element) => {
  const button = element.querySelector('.show-more')
  const content = element.querySelector('.richtext')
  const overlay = element.querySelector('.overlay')

  button.addEventListener('click', () => {
    if (content.style.maxHeight == '9rem') {
      content.style.maxHeight = content.scrollHeight + 'px'
      overlay.classList.add('opacity-0')
      button.innerHTML = 'Weniger anzeigen'
    } else {
      content.style.maxHeight = '9rem'
      overlay.classList.remove('opacity-0')
      button.innerHTML = 'Mehr anzeigen'
    }
  })
})

up.compiler('.js-testimonials', (element) => {
  new Splide(element, {
    padding: '10rem',
    breakpoints: {
      1024: {
        padding: '1.5rem',
      }
    },
  }).mount()
})

up.compiler('.js-gallery-item', (element) => {
  new VenoBox({
    selector: '.js-gallery-item',
    spinner: 'flow',
  })
})

up.compiler('.js-collapse', (element) => {
  const button = element.querySelector('.button')
  const content = element.querySelector('.content')

  const setMaxHeight = () => {
    if (content.style.maxHeight != '0px') content.style.maxHeight = content.scrollHeight + 'px'
  }
  setTimeout(setMaxHeight, 500)

  button.addEventListener('click', () => {
    if (content.style.maxHeight == '0px') {
      content.style.maxHeight = content.scrollHeight + 'px'
    } else {
      content.style.maxHeight = '0px'
    }
  })

  return () => button.removeEventListener('click')
})

up.compiler('.js-video', (element) => {
  const player = new Plyr(element)
})

up.compiler('.js-link-to-clipboard', (element) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()
    const href = element.getAttribute('href')
    navigator.clipboard.writeText(href)
    alert("Link in Zwischenablage kopiert: " + href)
  })

  return () => element.removeEventListener('click')
})

up.compiler('.js-phone-field', (element) => {
  new Cleave(element, {
      phone: true,
      phoneRegionCode: 'ch'
  })
})

up.compiler('.js-contact-form, .js-offer-form', () => {
  hyperform.addTranslation("de", {
    TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    ValueMissing: "Bitte füllen Sie dieses Feld aus.",
    RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
    InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
    PatternMismatch:"Bitte halten Sie sich an das vorgegebene Format.",
    "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
    "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
    "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
    "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
    "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
    "any value": "jeder Wert",
    "any valid value": "jeder gültige Wert",
  })
  hyperform.setLanguage("de");
  hyperform(window, {
    revalidate: 'onblur',
  })
})

up.compiler('.sauna_type .js-radio-card', (element) => {
  const allSections = document.querySelectorAll('.js-form-section')
  const hideAll = () => {
    allSections.forEach((section) => {
      section.classList.add('hidden')
      section.querySelectorAll('input').forEach((input) => {
        input.required = false
      })
    })
  }
  const show = (element) => {
    document.querySelector(element).classList.remove('hidden')
    document.querySelector(element).querySelectorAll('input').forEach((input) => {
      input.required = true
    })
  }
  const update = () => {
    if (element.checked == true) {
      switch (element.value) {
        case 'sauna_barrel':
          hideAll()
          show('.js-form-section.sauna-model')
          break
        default:
          hideAll()
      }
    }
  }

  element.addEventListener('change', update)
})
